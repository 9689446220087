jQuery(document).ready(function(){
    jQuery.ajaxSetup({ cache: true });
    jQuery.getScript('//connect.facebook.net/en_US/sdk.js', function(data, textStatus, jqxhr){
        try {
            FB.init({
                appId: '348137433269',
                version: 'v2.5'
            });
        }
        catch(err) {
            //Block of code to handle errors
        }    
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            jQuery('#myModal').remove();
        }
        else {
            if(typeof Cookies.get('fb') === "undefined")
            {
                jQuery.get( "/fb", function( data ) {
                    jQuery( ".modal-body" ).html( data );
                    setTimeout(function() { jQuery('#myModal').modal('show'); }, 3000);
                    Cookies.set("fb", "foo", { expires: 2 });
                    setTimeout(function() { jQuery('#myModal').modal('hide'); jQuery('#myModal').remove(); }, 13000);
                    try{
                        FB.XFBML.parse();
                    }catch(ex){}
                });
            }
            else{
                jQuery('#myModal').remove();
                try{
                    FB.XFBML.parse();
                }catch(ex){}
            }
        }
    });
    if(typeof Cookies.get('accept_cookie_policy') === "undefined")
    {
        jQuery("#cookies_agreement").show();
    }
});
function close_cookie()
{
    Cookies.set("accept_cookie_policy", "foo", { expires: 720 });
    jQuery("#cookies_agreement").hide();
}